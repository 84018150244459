import React from 'react'

const TermsDetail = () => {
    return (
        <div>
            <div className="wrapper-withouttopside">
                <div className="wrapper-integration">
                    <div className="wrapper-structure">
                        <div className="left-side-grid" style={{ background: "#fff" }}></div>
                        <div className="mid-side-grid">
                            <div className="termscontent">
                                <div className="termsinnercontent">
                                    <p className="datepara">Last Updated: November 5, 2024</p>
                                    <div className='mainouterdiv_outer'>
                                        <div className='mainouterdiv_iner'>
                                            <h4 className='maintophngd'>
                                                1. Introduction and Acceptance
                                            </h4>
                                            <h6 className='seclinghthndg'>
                                                1.1 Binding Agreement

                                            </h6>
                                            <p className='commmmonpara'>
                                                These Terms of Service (“Terms”), along with our Privacy Policy and any other policies, guidelines, or agreements referenced herein (collectively, the “Agreement”), constitute a legally binding contract between you (whether an individual or entity, “User,” “you,” or “your”) and Olympus AI, including its affiliates, subsidiaries, and successors (“Olympus AI,” “we,” “us,” or “our”) governing your access to and use of our AI-powered platform, AI employees, blockchain-enabled services, and related technologies (collectively, the “Services”).
                                            </p>
                                            <div className='mainitemsdiv'>
                                                <h6 className='seclinghthndg'>
                                                    1.2 Eligibility and Authority

                                                </h6>
                                                <p className='ullipara'>
                                                    By accessing or using our Services, you represent and warrant that:

                                                </p>
                                                <ul className=''>


                                                    <li className=''>
                                                        You are at least 18 years old and legally capable of entering into this Agreement.
                                                    </li>
                                                    <li className=''>
                                                        You have the authority to bind any entity you represent.
                                                    </li>
                                                    <li className=''>
                                                        Your use of the Services is not prohibited by any applicable law or regulation.
                                                    </li>
                                                    <li className=''>
                                                        You acknowledge the inherent risks of using AI and blockchain technology.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='secsection 2'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        2. Description of Services and Assumption of Risk
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        2.1 AI Employees and Platform Capabilities

                                                    </h6>
                                                    <p className='ullipara'>
                                                        Olympus AI provides access to AI employees capable of performing a variety of autonomous tasks, including:

                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            Content creation and management
                                                        </li>
                                                        <li className=''>
                                                            Financial analysis and trading
                                                        </li>
                                                        <li className=''>
                                                            Marketing and brand management
                                                        </li>
                                                        <li className=''>
                                                            Product management
                                                        </li>
                                                        <li className=''>
                                                            Customer support
                                                        </li>
                                                        <li className=''>
                                                            Real-world task execution through blockchain integration
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        2.2 Assumption of Risk
                                                    </h6>
                                                    <p className='ullipara'>
                                                        By using our Services, you acknowledge and assume the following risks:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            AI employees may execute autonomous decisions that can impact your business.
                                                        </li>
                                                        <li className=''>
                                                            Blockchain transactions are irreversible.
                                                        </li>
                                                        <li className=''>
                                                            Technical or third-party service disruptions may affect service availability.
                                                        </li>
                                                        <li className=''>
                                                            Regulatory changes may impact the Services or related technologies.
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        2.3 Service Limitations and Disclaimers
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We disclaim all guarantees regarding:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            Service availability, continuity, and performance.
                                                        </li>
                                                        <li className=''>
                                                            Outcomes or reliability of autonomous AI employee actions.
                                                        </li>
                                                        <li className=''>
                                                            Performance of third-party services or blockchain networks integrated with our platform.
                                                        </li>
                                                        <li className=''>
                                                            The quality, accuracy, or suitability of any outputs generated by our AI employees, which may vary and are provided “as-is.”
                                                        </li>
                                                        <li className=''>
                                                            We reserve the right to modify, suspend, or discontinue any part of the Services without liability.
                                                        </li>


                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='secsection 3'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        3. User Obligations and Prohibited Conduct
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        3.1 Acceptable Use

                                                    </h6>
                                                    <p className='ullipara'>
                                                        You agree to:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            Comply with all applicable laws and regulations.
                                                        </li>
                                                        <li className=''>
                                                            Maintain accurate and complete account information.
                                                        </li>
                                                        <li className=''>
                                                            Secure your access credentials.
                                                        </li>
                                                        <li className=''>
                                                            Use the Services solely for authorized business purposes.
                                                        </li>
                                                        <li className=''>
                                                            Review and confirm actions initiated by AI employees as necessary.
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        3.2 Prohibited Conduct
                                                    </h6>
                                                    <p className='ullipara'>
                                                        You agree not to:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            Reverse engineer, decompile, or hack any part of the Services.
                                                        </li>
                                                        <li className=''>
                                                            Use the Services for illegal, malicious, or unauthorized purposes.
                                                        </li>
                                                        <li className=''>
                                                            Interfere with the Services, servers, or networks.
                                                        </li>
                                                        <li className=''>
                                                            Circumvent usage restrictions, security measures, or Service limitations.
                                                        </li>
                                                        <li className=''>
                                                            Share or transfer account access to unauthorized parties.
                                                        </li>
                                                        <li className=''>
                                                            Engage in activities harmful to Olympus AI’s reputation, Services, or users.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='secsection 4'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        4. Intellectual Property Rights
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        4.1 Ownership and Licensing
                                                    </h6>

                                                    <ul className=''>
                                                        <li className=''>
                                                            All intellectual property in the Services, including AI models, algorithms, and platform technology, remains the exclusive property of Olympus AI.
                                                        </li>
                                                        <li className=''>
                                                            Output generated by AI employees is licensed, not sold, to you in accordance with these Terms.
                                                        </li>
                                                        <li className=''>
                                                            You retain rights to your input data, subject to the licenses granted herein.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        4.2 License to Use Services
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We grant you a limited, non-exclusive, non-transferable, revocable license to:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            Access and use the Services as permitted by these Terms.
                                                        </li>
                                                        <li className=''>
                                                            Use AI-generated content solely for authorized business purposes.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        4.3 Restrictions on Use
                                                    </h6>
                                                    <p className='ullipara'>
                                                        You may not:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            Modify, create derivative works of, or use Olympus AI intellectual property for unauthorized purposes.
                                                        </li>
                                                        <li className=''>
                                                            License, sublicense, sell, or transfer rights in the Services.
                                                        </li>



                                                    </ul>
                                                </div>
                                            </div>


                                            <div className='secsection 5'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        5. Payment and Token Economics
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        5.1 OAI Token Usage
                                                    </h6>

                                                    <ul className=''>
                                                        <li className=''>
                                                            Access to certain Services requires OAI tokens.
                                                        </li>
                                                        <li className=''>
                                                            Token values may fluctuate; Olympus AI does not guarantee their value.
                                                        </li>
                                                        <li className=''>
                                                            Olympus AI reserves the right to modify token requirements, pricing, or availability at its discretion.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        5.2 Payment Terms
                                                    </h6>

                                                    <ul className=''>
                                                        <li className=''>
                                                            All payments and token purchases are final, with no refunds.
                                                        </li>
                                                        <li className=''>
                                                            You are responsible for all applicable taxes.
                                                        </li>
                                                        <li className=''>
                                                            Transactions conducted by AI employees may incur additional fees.
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>

                                            <div className='secsection 6'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        6. Data Privacy and Security
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        6.1 Data Collection and Usage
                                                    </h6>

                                                    <ul className=''>
                                                        <li className=''>
                                                            We collect and process data per our Privacy Policy.
                                                        </li>
                                                        <li className=''>
                                                            You are responsible for ensuring your data is accurate and lawful.
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.2 Security Measures
                                                    </h6>

                                                    <ul className=''>
                                                        <li className=''>
                                                            We implement industry-standard security measures but cannot guarantee complete security.
                                                        </li>
                                                        <li className=''>
                                                            You agree to notify Olympus AI promptly of any security breaches.
                                                        </li>

                                                    </ul>
                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.3 Compliance with Privacy Regulations
                                                    </h6>

                                                    <ul className=''>
                                                        <li className=''>
                                                            Olympus AI complies with relevant data protection laws. You are responsible for obtaining any necessary consents for data processing.
                                                        </li>


                                                    </ul>
                                                </div>

                                            </div>

                                            <div className='secsection 7'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        7. Limitation of Liability and Disclaimers
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        7.1 Disclaimer of Warranties
                                                    </h6>
                                                    <p className='ullipara'>
                                                        THE SERVICES, INCLUDING ALL CONTENT, AI EMPLOYEES, AND MATERIALS, ARE PROVIDED “AS IS” AND “AS AVAILABLE,” WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. OLYMPUS AI DOES NOT GUARANTEE THE QUALITY, ACCURACY, OR SUITABILITY OF OUTPUTS GENERATED BY THE AI EMPLOYEES.
                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        7.2 Limitation of Liability
                                                    </h6>
                                                    <p className='ullipara'>
                                                        TO THE MAXIMUM EXTENT PERMITTED BY LAW, OLYMPUS AI SHALL NOT BE LIABLE FOR:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            Indirect, incidental, or consequential damages.
                                                        </li>
                                                        <li className=''>
                                                            Lost profits, data loss, or business interruption.
                                                        </li>
                                                        <li className=''>
                                                            Actions or decisions made by AI employees.
                                                        </li>
                                                        <li className=''>
                                                            Blockchain or third-party service disruptions.
                                                        </li>





                                                    </ul>
                                                </div>


                                            </div>

                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        8. Indemnification
                                                    </h4>


                                                    <p className='ullipara'>
                                                        You agree to defend, indemnify, and hold harmless Olympus AI, its affiliates, officers, and agents from any claims, damages, or expenses arising from:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            Your use of the Services.
                                                        </li>
                                                        <li className=''>
                                                            Violations of these Terms.
                                                        </li>
                                                        <li className=''>
                                                            Any third-party claims related to your data, content, or actions.
                                                        </li>






                                                    </ul>

                                                </div>



                                            </div>

                                            <div className='secsection 9'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        9. Term and Termination
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        9.1 Term and Termination Rights
                                                    </h6>
                                                    <p className='commmmonpara'>
                                                        These Terms remain in effect until terminated by either party. Olympus AI may terminate your access for any breach of these Terms or at its sole discretion.
                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        9.2 Consequences of Termination
                                                    </h6>
                                                    <p className='ullipara'>
                                                        Upon termination:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            All licenses granted will terminate.
                                                        </li>
                                                        <li className=''>
                                                            Outstanding fees become due immediately.
                                                        </li>
                                                        <li className=''>
                                                            Olympus AI may delete your data post-termination.
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className='secsection 10'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        10. Governing Law and Dispute Resolution
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        10.1 Governing Law
                                                    </h6>
                                                    <p className='commmmonpara'>
                                                        These Terms shall be governed by and construed in accordance with the laws of England and Wales. All legal disputes arising out of or in connection with these Terms shall be resolved exclusively in the courts of London, UK.
                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        10.2 Arbitration Agreement
                                                    </h6>
                                                    <p className='ullipara'>
                                                        All disputes arising from or relating to these Terms shall be resolved through binding arbitration in London, UK, under the LCIA Rules.
                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        10.3 Waiver of Class Actions

                                                    </h6>
                                                    <p className='ullipara'>
                                                        Both parties waive the right to bring or participate in any class or representative actions.
                                                    </p>

                                                </div>

                                            </div>

                                            <div className='secsection 11'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        11. Modifications
                                                    </h4>

                                                    <p className='commmmonpara'>
                                                        Olympus AI reserves the right to modify these Terms at any time without notice. Continued use of the Services indicates acceptance of any modified Terms.
                                                    </p>

                                                </div>


                                            </div>
                                            <div className='secsection 12'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        12. General Provisions
                                                    </h4>

                                                    <h6 className='seclinghthndg'>
                                                        12.1 Force Majeure
                                                    </h6>
                                                    <p className='commmmonpara'>
                                                        Olympus AI shall not be liable for delays or failures caused by circumstances beyond its control.
                                                    </p>

                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        12.2 Assignment


                                                    </h6>
                                                    <p className='ullipara'>
                                                        Olympus AI may assign its rights and obligations under these Terms; you may not assign your rights without our consent.
                                                    </p>

                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        12.3 Severability

                                                    </h6>
                                                    <p className='ullipara'>
                                                        If any provision is found invalid, the remaining provisions shall remain in effect.
                                                    </p>

                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        12.4 Entire Agreement

                                                    </h6>
                                                    <p className='ullipara'>
                                                        These Terms constitute the entire agreement between you and Olympus AI.
                                                    </p>

                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        12.5 No Waiver

                                                    </h6>
                                                    <p className='ullipara'>
                                                        No delay in exercising any right shall constitute a waiver of that right.
                                                    </p>

                                                </div>


                                            </div>
                                            <div className='secsection 13'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        13. Contact Information
                                                    </h4>

                                                    <p className='commmmonpara'>
                                                        For questions, contact:
                                                    </p>

                                                    <p className='commmmonpara'>
                                                        Legal Department
                                                    </p>

                                                    <p className='commmmonpara'>
                                                        support@olympusai.io
                                                    </p>

                                                    <p className='commmmonpara'>
                                                        By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms.
                                                    </p>




                                                </div>




                                            </div>



                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div className="right-side-grid" style={{ background: "#fff" }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsDetail
