import React, { useEffect, useRef } from 'react'
import "./faster.scss"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

const Faster = () => {
    const textRef = useRef(null);

    useEffect(() => {
      const textElement = textRef.current;
      const textContent = textElement.textContent;
      textElement.innerHTML = textContent.split('').map(char => `<span>${char}</span>`).join('');
  
      const chars = textElement.querySelectorAll('span');
  
      gsap.fromTo(chars,
        { scale: 0.8, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: 'power1.out',
          stagger: {
            amount: 1,
            from: "start"
          },
          delay: 0, 
          scrollTrigger: {
            trigger: textElement,
            start: 'top 70%',
            once: true, 
          }
        }
      );
    }, []);

    const {t} = useTranslation();
    return (
        <>
            <div className="wrapper-withouttopside" id='faster'>
                <div className="wrapper-faster">
                    <div className="wrapper-structure">
                        <div className="left-side-grid"></div>
                        <div className="mid-side-grid">
                            <section className="main-faster">
                                <img src="\assets\faster\faster-bg.png" alt="img" className='img-fluid faster-bg' />
                                <img src="\assets\faster\faster-bg-mbl.png" alt="img" className='img-fluid faster-bg-mbl d-none' />
                                <div className="main-content">
                                    <h4 ref={textRef}>{t('Faster-t1')} <br />{t('Faster-t2')}</h4>
                                    <p>{t('Faster-t3')}</p>
                                    <div class="btn-container">
                                    <a href="https://olympus-demo.com" target="_blank" rel="noopener noreferrer">
 

                                        <button className="btn-white">
                                            <span class="text">{t('Faster-t4')}</span>
                                            <div class="icon-container">
                                                <div class="icon icon--left">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="#2C83F6" />
                                                    </svg>
                                                </div>
                                                <div class="icon icon--right">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="#2C83F6" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </button>
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="right-side-grid"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faster
