import { useState } from 'react';
import './app.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import i18next from 'i18next';
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Faqs from './components/Faqs/Faqs.js';
import Terms from './components/Terms/Terms.js';
import Privacy from './components/Privacy/Privacy.js';


function App() {
  var languageselected = localStorage.getItem("codelanguage");
  const [countrycode, setcountrycode] = useState(null);
  console.log("countrucode", countrycode);
  const ipURL = "https://ip.nf/me.json";
  const axiosCallForLocation = () => {
    axios
      .get(ipURL)
      .then((response) => {
        if (
          response?.data?.ip?.country_code === "JP" ||
          response?.data?.ip?.country_code === "JPN"
        ) {
          setcountrycode("jp");
        } else {
          setcountrycode("en");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "jp"],
    lng: languageselected ? languageselected : countrycode,
    fallbackLng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });


  return (
    <>
          <Router>
            <Routes>
              <Route exact path='/' element={<Landing />} />
              <Route exact path='/faqs' element={<Faqs />} />
              <Route exact path='/terms' element={<Terms />} />
              <Route exact path='/privacy' element={<Privacy />} />
            </Routes>
          </Router>
    </>
  );
}

export default App;
