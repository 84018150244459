import React from 'react'

const PrivacyDetail = () => {
    return (
        <div>
            <div className="wrapper-withouttopside">
                <div className="wrapper-integration">
                    <div className="wrapper-structure">
                        <div className="left-side-grid" style={{ background: "#fff" }}></div>
                        <div className="mid-side-grid">
                            <div className="privacycontent">
                                <div className="privacyinnercontent">
                                    <p className="datepara">Last Updated: November 5, 2024</p>

                                    <div className='mainouterdiv_outer'>
                                        <div className='mainouterdiv_iner'>

                                            <p className='commmmonpara '>
                                                Olympus AI values your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our services, including our website, platform, and AI-driven applications (collectively, the “Services”). By using the Services, you consent to the practices described in this policy.
                                            </p>

                                            <div className='secsection 2'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        1. Data Controller Information
                                                    </h4>

                                                    <p className='ullipara'>
                                                        Olympus AI, headquartered at [Address], is the data controller for the processing of your personal data under applicable data protection laws, including but not limited to the EU General Data Protection Regulation (“GDPR”) and the California Consumer Privacy Act (“CCPA”).

                                                    </p>
                                                    <p className='ullipara'>
                                                        For questions about this Privacy Policy or our data practices, please contact us at:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Email: privacy@olympusai.io
                                                        </li>


                                                    </ul>
                                                </div>

                                            </div>
                                            <div className='secsection 3'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        2. Scope of the Privacy Policy
                                                    </h4>

                                                    <p className='ullipara'>
                                                        This Privacy Policy applies to:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Personal data collected and processed when you access or use our Services.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            All users, including customers, prospective customers, visitors, and partners.
                                                            It does not cover:

                                                        </li>
                                                        <li className='ulliparass'>
                                                            Third-party services or websites linked to or integrated with our Services.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Anonymized data that cannot be linked to an individual.
                                                        </li>



                                                    </ul>
                                                </div>

                                            </div>
                                            <div className='secsection 4'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        3 Data Collection Practices
                                                    </h4>
                                                    <p className='commmmonpara p-0'>
                                                        We collect data directly from you, through automated technologies, and from third-party sources. The types of personal data we collect include:



                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        3.1 Data You Provide Directly
                                                    </h6>
                                                    <p className='ullipara'>
                                                        When you use our Services, you may provide data, including but not limited to:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Account Information: Name, email address, username, password, contact information, and job title.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Payment Information: Billing address and payment details for transaction processing. (Note: Olympus AI does not store payment details directly; they are processed by a third-party payment provider).
                                                        </li>


                                                        <li>
                                                            Communication Data: Feedback, customer support inquiries, and correspondence with Olympus AI.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        3.2 Data Collected Automatically
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We may automatically collect certain data about your device, browsing actions, and usage patterns:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Usage Data: Information about how you access and use the Services, including IP address, browser type, device ID, access times, pages viewed, and referring website addresses.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Device Data: Device type, operating system, and network data.
                                                        </li>



                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        3.3 Data from Third-Party Sources
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We may receive data about you from third-party sources to improve the Services, including:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Social Media and Authentication Services: Information from social media and identity verification providers if you connect your accounts with Olympus AI.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Analytics Providers: Data on usage patterns for better user experience and optimization.
                                                        </li>



                                                    </ul>
                                                </div>
                                            </div>


                                            <div className='secsection 5'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        4. Purposes of Data Processing
                                                    </h4>
                                                    <p className='ullipara'>
                                                        We process your data to provide, improve, and secure our Services, ensure regulatory compliance, and communicate with you. Specific purposes include:
                                                    </p>

                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Service Delivery: Provide and personalize our Services, including AI-driven outputs.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Account Management: Verify your identity, administer your account, and manage user relationships.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Transaction Processing: Process payments and manage subscriptions.
                                                        </li>

                                                        <li className='ulliparass'>
                                                            Analytics and Research: Analyze usage data for trends, feature optimization, and quality assurance.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Security and Fraud Prevention: Monitor and protect against fraud, abuse, unauthorized access, and security incidents.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Communication: Send you service-related notifications, updates, and marketing communications in compliance with applicable laws.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Legal Compliance: Fulfill legal obligations and respond to government requests or legal processes.
                                                        </li>
                                                    </ul>
                                                </div>


                                            </div>

                                            <div className='secsection 6'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        5. Legal Bases for Data Processing
                                                    </h4>
                                                    <p className='ullipara'>
                                                        Our processing of personal data is based on one or more of the following legal bases:
                                                    </p>

                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Performance of a Contract: Processing necessary to fulfill our contractual obligations to you.

                                                        </li>
                                                        <li className='ulliparass'>
                                                            Legitimate Interests: Processing to pursue our legitimate interests, such as improving our Services, safeguarding our platform, and managing relationships, provided these interests are not overridden by your rights.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Consent: Processing based on your consent for specific uses of data, such as marketing. You may withdraw consent at any time.

                                                        </li>

                                                        <li className='ulliparass'>
                                                            Legal Obligation: Processing necessary to comply with legal and regulatory requirements.

                                                        </li>
                                                    </ul>
                                                </div>



                                            </div>

                                            <div className='secsection 7'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        6. Data Sharing and Disclosure
                                                    </h4>

                                                    <p className='commmmonpara p-0'>
                                                        We do not sell your personal data. However, we may share your data in the following ways:
                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.1 Service Providers
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We engage third-party service providers for:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Payment processing, data storage, customer support, analytics, and fraud prevention.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            These providers have limited access to your data solely for providing services on our behalf and are bound by data protection obligations.
                                                        </li>






                                                    </ul>
                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.2 Business Transfers
                                                    </h6>
                                                    <p className='ullipara'>
                                                        In the event of a merger, acquisition, reorganization, or sale of assets, your data may be transferred as part of the transaction. We will notify you and take measures to ensure the protection of your data.
                                                    </p>

                                                </div>


                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.3 Compliance with Legal Obligations
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We may disclose your data as required by law or in response to lawful requests by public authorities, including:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Compliance with legal obligations, protection of rights, or to protect Olympus AI’s security and the safety of others.
                                                        </li>







                                                    </ul>
                                                </div>


                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.4 Affiliates and Subsidiaries
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We may share your data with our affiliates or subsidiaries for internal business purposes, subject to the terms of this Privacy Policy.
                                                    </p>

                                                </div>


                                            </div>

                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        7. International Data Transfers
                                                    </h4>


                                                    <p className='ullipara'>
                                                        Olympus AI may transfer and process your data in countries outside your jurisdiction, including the United States, Europe, and other regions where we operate. When we transfer your data internationally:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            We ensure that such transfers comply with applicable data protection laws.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            We use Standard Contractual Clauses or rely on other legally recognized transfer mechanisms where required.
                                                        </li>







                                                    </ul>

                                                </div>



                                            </div>



                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        8. Data Retention
                                                    </h4>


                                                    <p className='ullipara'>
                                                        We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Retention periods are determined based on:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            The duration of our relationship with you.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Legal, contractual, or regulatory requirements.
                                                        </li>

                                                        <li className='ulliparass'>
                                                            Legitimate business purposes, such as improving security, fraud detection, or resolving disputes.
                                                        </li>







                                                    </ul>

                                                </div>



                                            </div>

                                            <div className='secsection 9'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        9. Data Security
                                                    </h4>

                                                    <p className='commmmonpara pb-2'>
                                                        We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. Measures include:
                                                    </p>
                                                    <ul className='pb-2'>
                                                        <li className='ulliparass'>
                                                            Data encryption, access controls, secure network architecture, and regular security audits.
                                                        </li>

                                                    </ul>
                                                    <p className='commmmonpara p-0'>
                                                        Despite our efforts, no security system is completely secure. You are responsible for maintaining the confidentiality of your account credentials and promptly notifying us of any security incidents.
                                                    </p>

                                                </div>


                                            </div>

                                            <div className='secsection 7'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        10. Your Rights and Choices
                                                    </h4>

                                                    <p className='commmmonpara p-0'>
                                                        Depending on your jurisdiction, you may have the following rights concerning your personal data:
                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        10.1 Access and Correction
                                                    </h6>
                                                    <p className='ullipara'>
                                                        You have the right to request access to your personal data and correct any inaccuracies.
                                                    </p>

                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        10.2 Deletion and Restriction
                                                    </h6>
                                                    <p className='ullipara'>
                                                        You may request deletion of your data under certain conditions, and in some cases, request restrictions on data processing.
                                                    </p>

                                                </div>


                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        10.3 Data Portability
                                                    </h6>
                                                    <p className='ullipara'>
                                                        You have the right to receive a copy of your data in a structured, machine-readable format.
                                                    </p>

                                                </div>


                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        10.4 Objection to Processing
                                                    </h6>
                                                    <p className='ullipara'>
                                                        You may object to processing based on legitimate interests and opt out of marketing communications.
                                                    </p>

                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        10.5 Withdraw Consent
                                                    </h6>
                                                    <p className='ullipara'>
                                                        Where we process data based on your consent, you may withdraw it at any time.
                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        10.6 Non-Discrimination (CCPA-specific)
                                                    </h6>
                                                    <p className='ullipara'>
                                                        Olympus AI will not discriminate against users for exercising their rights under applicable privacy laws, including CCPA.
                                                    </p>
                                                    <p className='ullipara'>
                                                        To exercise these rights, please contact us at privacy@olympus-ai.com. We may request verification to ensure the security of your data.
                                                    </p>

                                                </div>







                                            </div>




                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        11. Cookies and Tracking Technologies
                                                    </h4>


                                                    <p className='ullipara'>
                                                        We use cookies and similar tracking technologies to improve your experience, analyze usage, and support functionality. Types of cookies include:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Essential Cookies: Necessary for basic functionality.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Performance and Analytics Cookies: Collect usage data for improvements.
                                                        </li>

                                                        <li className='ulliparass'>
                                                            Advertising Cookies: Personalize and display relevant ads.
                                                        </li>







                                                    </ul>

                                                    <p className='ullipara'>
                                                        You may manage your cookie preferences through your browser settings. However, disabling certain cookies may affect your experience.
                                                    </p>

                                                </div>



                                            </div>




                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        12. Children’s Privacy
                                                    </h4>


                                                    <p className='ullipara'>
                                                        Our Services are not intended for children under 18. We do not knowingly collect or process data from children. If we learn that we have collected data from a child without appropriate consent, we will delete such data promptly.
                                                    </p>


                                                </div>









                                            </div>
                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        13. Policy Changes
                                                    </h4>


                                                    <p className='ullipara'>
                                                        We may update this Privacy Policy periodically to reflect changes in our practices, legal requirements, or operational needs. When changes are made:
                                                    </p>

                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            We will notify you by updating the “Last Updated” date at the top of this policy.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            In cases of significant changes, we may provide additional notice or seek your consent where required by law.
                                                        </li>







                                                    </ul>



                                                    <p className='ullipara'>
                                                        Your continued use of the Services after any changes to this Privacy Policy constitutes acceptance of the updated terms.
                                                    </p>


                                                </div>









                                            </div>

                                            <div className='secsection 13'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        14. Contact Us
                                                    </h4>
                                                    <p className='ullipara'>
                                                        For questions, concerns, or to exercise your data protection rights, please contact our Data Protection Officer at:

                                                    </p>


                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Email: privacy@olympusai.io
                                                        </li>








                                                    </ul>
                                                    <p className='ullipara'>
                                                        By using our Services, you acknowledge that you have read, understood, and agree to the terms outlined in this Privacy Policy.

                                                    </p>



                                                </div>




                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-side-grid" style={{ background: "#fff" }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyDetail
