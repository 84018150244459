import React, { useEffect, useRef, useState } from 'react'
import "./omp.scss"
import Spline from '@splinetool/react-spline';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

const Omp = () => {
    const textRef = useRef(null);
    const [showHighRes, setShowHighRes] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowHighRes(true);
        }, 5000);


        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const textElement = textRef.current;
        const textContent = textElement.textContent;
        textElement.innerHTML = textContent.split('').map(char => `<span>${char}</span>`).join('');

        const chars = textElement.querySelectorAll('span');

        gsap.fromTo(chars,
            { scale: 0.8, opacity: 0 },
            {
                scale: 1,
                opacity: 1,
                duration: 1,
                ease: 'power1.out',
                stagger: {
                    amount: 1,
                    from: "start"
                },
                delay: 0,
                scrollTrigger: {
                    trigger: textElement,
                    start: 'top 70%',
                    once: true,
                }
            }
        );
    }, []);

    const { t } = useTranslation();
    return (
        <>
            <div className="wrapper-withouttopside" id='oai'>
                <div className="wrapper-omp">
                    <div className="wrapper-structure">
                        <div className="left-side-grid"></div>
                        <div className="mid-side-grid">
                            <section className="main-omp">
                                <div className="parent">
                                    <div className="left-side">
                                        <div className="main-content">
                                            <h6>{t('Omp-t1')}</h6>
                                            <h3 ref={textRef}>{t('Omp-t2')}</h3>
                                            <p>{t('Omp-t3')}</p>
                                            <p>{t('Omp-t4')}</p>
                                            <div class="btn-container">
                                                <a href='https://gems.vip/olympus' target='_blank' className="btn-blue">
                                                    <span class="text">{t('Omp-t5')}</span>
                                                    <div class="icon-container">
                                                        <div class="icon icon--left">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="#2C83F6" />
                                                            </svg>
                                                        </div>
                                                        <div class="icon icon--right">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="#2C83F6" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <div className="wrapper-img">
                                        {/* {!showHighRes && (
                                                <img src="\assets\omp\omp-main-img.png" alt="img" className='img-fluid' />
                                            )}
                                            {showHighRes && ( */}
                                                <Spline className='omp-spline' scene="https://prod.spline.design/e0NxLc5I-zfpsN9m/scene.splinecode" />
                                            {/* )} */}


                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="right-side-grid"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Omp
