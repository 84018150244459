import React, { useEffect } from 'react';
import './footer.scss';
import { useNavigate, useLocation } from 'react-router-dom'; // Using useNavigate for routing and useLocation for getting the URL details
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { pathname, hash } = useLocation(); // Get current URL path and hash
  const navigate = useNavigate(); // Used for navigation
  const { t } = useTranslation();

  // Section offsets for different screen sizes
  const sectionOffsets = {
    desktop: {
      section1: 2500,
      section2: 1800,
      section3: 1200,
      section4: 600,
      section5: 20,
    },
    mobile: {
      section1: 0,
      section2: 0,
      section3: 0,
      section4: 0,
      section5: 0,
    },
  };

  // Get the correct offset based on screen size
  const getSectionOffsets = () => {
    return window.innerWidth >= 768 ? sectionOffsets.desktop : sectionOffsets.mobile;
  };

  // Function to scroll to a section with an offset
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const offsets = getSectionOffsets();
      const offset = offsets[id] || 0;
      const elementTop = section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementTop - offset,
        behavior: 'smooth',
      });
    }
  };

  // Scroll to the section if on the landing page and the hash is present
  useEffect(() => {
    if (pathname === '/' && hash) {
      const sectionId = hash.replace('#', '');
      scrollToSection(sectionId);
    }
  }, [hash, pathname]);

  // Function to handle link clicks
  const handleScrollClick = (sectionId) => {
    if (pathname === '/') {
      // If already on the landing page, scroll directly
      scrollToSection(sectionId);
    } else {
      // Navigate to the landing page with the section hash
      navigate(`/#${sectionId}`);

      // Use a timeout to wait for the page to load, then scroll
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 100); // Adjust this delay if needed (100ms should be fine)
    }
  };

  return (
    <>
      <section className="main-footer">
        <div className="parent">
          <div className="left-side">
            <div className="inside-content">
              <img src="/logo-new.svg" alt="img" className="img-fluid logo-img" />
              <div className="social-icons">
                {/* Social Media Icons */}
              </div>
              <p>{t('Footer-t1')}</p>
              <div className="twice-links">
                <Link to="/terms">Terms</Link>
                <span></span>
                <Link to="/privacy">Privacy</Link>
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="parent-inside">
              <div className="single-list">
                <h6>{t('Navbar-t3')}</h6>

                {/* Links to scroll to specific sections */}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollClick('section1');
                  }}
                  href="#section1"
                >
                  {t('Third-t2')}
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollClick('section2');
                  }}
                  href="#section2"
                >
                  {t('Third-t8')}
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollClick('section3');
                  }}
                  href="#section3"
                >
                  {t('Third-t11')}
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollClick('section4');
                  }}
                  href="#section4"
                >
                  {t('Third-t14')}
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollClick('section5');
                  }}
                  href="#section5"
                >
                  {t('Third-t17')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <p className="d-none right-reserved-text">©2024 Olympus AI. All rights reserved.</p>
        <div className="twice-links d-none right-reserved-text">
          <Link to="/terms">Terms</Link>
          <span></span>
          <Link to="/privacy">Privacy</Link>
        </div>
      </section>
    </>
  );
};

export default Footer;
